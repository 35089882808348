<template>
  <b-container fluid>
    <b-card class="justify-content-center">
      <div class="custom-form-flex pb-4">
        <b-form id="alter-form" class="alter-form">
          <div
            class="config-div-grid config-size align-items-center ml-4 pr-4 pb-4"
          >
            <div class="config-desc">
              CATEGORIA
            </div>
            <div>
              <b-form-select
                class="text-align-left"
                v-model="selectedCategory"
                :state="validateField('category')"
                :options="categoryOptions"
                v-on:change="onChangeCategorySelect"
              />
            </div>
          </div>
          <div
            class="config-div-grid config-size align-items-center ml-4 pr-4 pb-4"
            v-if="!!subCategoryOptions.length"
          >
            <div class="config-desc">
              SUBCATEGORIA
            </div>
            <div>
              <b-form-select
                class="text-align-left"
                v-model="selectedSubCategory"
                :state="validateField('subCategory')"
                :options="subCategoryOptions"
                v-on:change="onChangeSubCategorySelect"
              />
            </div>
          </div>
          <div
            class="config-div-grid config-size align-items-center ml-4 pr-4 pb-4"
            v-if="!!manifestationOptions.length"
          >
            <div class="config-desc">
              MANIFESTAÇÃO
            </div>
            <div>
              <b-form-select
                class="text-align-left"
                v-model="selectedManifestation"
                :state="validateField('manifestation')"
                :options="manifestationOptions"
                v-on:change="onChangeManifestationSelect"
              />
            </div>
          </div>
          <div class="config-div-grid align-items-center ml-4 pr-4 pb-4">
            <div class="config-desc">
              MENSAGEM
            </div>
            <div>
              <b-form-textarea
                id="textarea"
                v-model="form.message"
                :state="validateField('message')"
                placeholder="Escreva uma descrição para sua solicitação…"
                size="lg"
              ></b-form-textarea>
            </div>
          </div>
          <div class="ml-4 pb-10">
            <UploadFile
              @updateFile="eventToUpdateFile"
              :multiple="false"
              :description="'Anexar documento para comprovação'"
            />
          </div>
          <b-button
            @click="onSubmit"
            type="button"
            class="ml-4"
            variant="primary"
            id="submitButtonText"
            :disabled="this.$store.getters.isAdmin"
          >
            Enviar
          </b-button>
        </b-form>
      </div>
      <success-modal
        ref="success-modal"
        description="Sua solicitação foi enviada."
        buttonText="Ok, entendi!"
        subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
        :protocol="this.protocol"
      />
    </b-card>
  </b-container>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import JMJOptions from "../../../../static-data/JMJOptions";
import { addOtherSolicitation } from "../../../../services/other-solicitation/other-solicitation";
import { inject } from "@vue/composition-api";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
const Default = {
  category: null,
  subCategory: null,
  manifestation: null,
  message: null,
  files: [],
};
export default {
  name: "other-solicitation-view",
  components: {
    UploadFile,
    SuccessModal,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  mounted() {
    this.buildCategoryOptions();
  },
  data() {
    return {
      form: {
        ...Default,
      },
      formSubmited: false,
      protocol: "",
      validations: {},
      selectedCategory: null,
      categoryOptions: [],
      selectedSubCategory: null,
      subCategoryOptions: [],
      selectedManifestation: null,
      manifestationOptions: [],
    };
  },
  methods: {
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return !!value;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    async onSubmit() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        try {
          this.globalLoading = true;
          this.protocol = await addOtherSolicitation(
            this.form,
            this.form.files
          );
          this.globalLoading = false;
          this.showSuccessModal();
          this.clean();
        } catch (error) {
          this.globalLoading = false;
          console.error(error);
        }
      }
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    buildCategoryOptions() {
      this.categoryOptions = JMJOptions.map((category) => ({
        value: category,
        text: category.name,
      }));
    },
    onChangeCategorySelect(selectedCategory) {
      const form = { ...this.form, category: selectedCategory.id };
      this.form = form;
      this.buildSubCategoryOptions();
    },
    buildSubCategoryOptions() {
      if (!this.selectedCategory["sub-category"]) {
        this.subCategoryOptions = [];
        delete this.validations.subCategory;
        return;
      }
      this.subCategoryOptions = this.selectedCategory["sub-category"].map(
        (subCategory) => ({
          value: subCategory,
          text: subCategory.name,
        })
      );
      this.selectedSubCategory = null;
      this.validations.subCategory = false;
    },
    onChangeSubCategorySelect(selectedSubCategory) {
      {
        selectedSubCategory;
      }
      const form = { ...this.form, subCategory: selectedSubCategory?.id };
      this.form = form;
      this.buildManifestationOptions();
    },
    buildManifestationOptions() {
      if (
        !this.selectedSubCategory ||
        !this.selectedSubCategory["manifestations"]
      ) {
        this.manifestationOptions = [];
        delete this.validations.manifestation;
        return;
      }
      this.manifestationOptions = this.selectedSubCategory[
        "manifestations"
      ].map((manifestation) => ({
        value: manifestation,
        text: manifestation.name,
      }));
    },
    onChangeManifestationSelect(selectedManifestation) {
      const form = { ...this.form, manifestation: selectedManifestation.id };
      this.form = form;
    },
    clean() {
      this.formSubmited = false;
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.subCategoryOptions = [];
      this.selectedManifestation = null;
      this.manifestationOptions = [];
      this.$root.$refs.UploadFile.removeAllFiles();
      this.form = { ...Default };
    },
  },
};
</script>

<style lang="scss">
div.card {
  border-color: transparent;
  border-radius: 1rem;
}

.config-size {
  height: 4rem;
}

.div-center {
  text-align: center;
  border-radius: 2rem;
}

.config-div-grid {
  display: grid;
  width: 100%;
}

@media (min-width: 460px) {
  .config-div-grid {
    grid-template-columns: 110px auto;
    padding-right: 0%;
  }
}

.config-desc {
  color: var(--grey-1);
  font-size: multiplier-default-font-size(1); // 12
}
</style>
